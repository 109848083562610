<template>
  <div>
    <el-card>
      <div class="demo-drawer__content">
        <el-form ref="formRef" :model="form" :rules="rules">
          <el-form-item label="内容标题" :label-width="80" prop="title">
            <el-input v-model="form.title" autocomplete="off" />
          </el-form-item>
          <el-form-item label="封面图片" :label-width="80" prop="coverUrl">
            <el-upload accept=".png,.jpeg,.jpg" action="#" class="upload-demo" :file-list="form.fileList" :http-request="upCover" :limit="1" list-type="picture-card" :on-error="handleError" :on-exceed="handleExceed" :on-preview="handlePreview" :on-remove="handleRemove">
              <el-icon class="el-icon--upload"><upload-filled /></el-icon>
              <div class="el-upload__text">
                <em>点击上传文件</em>
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="海报图片" :label-width="80" prop="bannerUrl">
            <el-upload accept=".png,.jpeg,.jpg" action="#" class="upload-demo" :file-list="form.bannerList" :http-request="upBannar" :limit="1" list-type="picture-card" :on-error="handleError" :on-exceed="handleExceed1" :on-preview="handlePreview" :on-remove="handleRemove1">
              <el-icon class="el-icon--upload"><upload-filled /></el-icon>
              <div class="el-upload__text">
                <em>点击上传文件</em>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="内容类别" :label-width="80" prop="articleCategoryId">
            <el-select v-model="form.articleCategoryId" placeholder="请选择内容类别">
              <template v-for="(item, index) in typeOption" :key="index">
                <el-option :label="item.name" :value="item.id" />
              </template>
            </el-select>
          </el-form-item>

          <el-form-item label="内容标签" :label-width="80" prop="tagIds">
            <el-select v-model="form.tagIds" multiple placeholder="请选择内容标签">
              <template v-for="(item, index) in tagOption" :key="index">
                <el-option :label="item.label" :value="item.data" />
              </template>
            </el-select>
          </el-form-item>

          <el-form-item label="内容状态" :label-width="80" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio :label="'PENDING_PUBLISH'">待发布</el-radio>
              <el-radio :label="'PUBLISH'">发布</el-radio>
              <el-radio :label="'DRAFT'">草稿</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="内容链接" :label-width="80" prop="jumpUrl">
            <el-input v-model="form.jumpUrl" autocomplete="off" />
            <div style="font-size: 12px; color: red">
              * 跳转链接需要符合小程序白名单要求的合法域名，请确认
            </div>
          </el-form-item>

          <el-form-item label="选择文章" :label-width="80" prop="jumpUrl">
            <el-button size="large" style="width: 200px" type="primary" @click="chooseWexinArticle()">
              选择公众号文章
            </el-button>
          </el-form-item>

          <!-- <el-form-item label="文章内容" :label-width="80">
            <vab-quill
              v-model="form.content"
              :min-height="400"
              :options="Richoptions"
            />
          </el-form-item> -->

          <el-form-item label="文章内容" :label-width="80">
            <!-- <vue-ueditor-wrap
              v-model="form.content"
              :config="editorConfig"
              :destroy="true"
              editor-id="editor"
              mode="listener"
              @beforeInit="addCustomButtom"
              @ready="ready"
            /> -->
            <Rich :htmlValue="form.content" ref="Editor" @contentChange="contentChange" />
          </el-form-item>

          <el-form-item align="center">
            <!-- <el-button @click="handleClose">取消</el-button> -->
            <el-button size="large" style="width: 200px" type="primary" @click="submitInfo()">
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <vab-upload ref="vabUploadRef" :limit="1" name="file" :size="2" url="/upload" @addImg="handleAddImg" />

    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div>
        <el-image :src="dialogImageUrl" />
      </div>
    </el-dialog>

    <el-dialog v-model="articleVisible" title="选择文章">
      <el-row>
        <el-col v-for="(item, index) in wexinArticleList" :key="index" :offset="1" :span="5">
          <el-card :body-style="{ padding: '0px' }">
            <el-image :fit="'contain'" :src="item.thumbUrl" />
            <div style="padding: 14px">
              <div>{{ item.name }}</div>
              <el-button style="width: 100px; margin-top: 10px" type="primary" @click="chooseWexinArticleItem(item.articleId)">
                选中
              </el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-pagination background :current-page="queryForm.pageNum" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </el-dialog>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onActivated,
  onMounted,
  reactive,
  toRefs,
  shallowRef,
  ref,
} from 'vue'
import { upLoadImg, getLabelLabel, editActiveTicket } from '@/api/active'
import {
  saveArticle,
  getCategoryList,
  editArticle,
  getContentDetail,
  getWexinArticle,
  getWeixinArticleDetail,
} from '@/api/content'
import { parseTime } from '@/utils/index'
import VabQuill from '@/extra/VabQuill'
import VabUpload from '@/extra/VabUpload'
import Rich from '@/components/rich/index'

export default defineComponent({
  name: 'ArticleEdit',
  components: {
    VabQuill,
    VabUpload,
    Rich,
  },
  setup(props, { emit }) {
    // 编辑器初始化

    const { proxy } = getCurrentInstance()
    const state = reactive({
      mode: 'default', // 或 'simple'
      dialogVisible: false,
      articleVisible: false,
      layout: 'prev, pager, next',
      total: 0,
      dialogImageUrl: '',
      formRef: null,
      vabUploadRef: null,
      editorRef: null,
      checkMenu: [],
      bannarList: [],
      size: '50%',
      form: {
        content: ``,
        tagIds: [],
      },
      rules: {
        title: [{ required: true, trigger: 'blur', message: '请输入文章标题' }],
        coverUrl: [
          { required: true, trigger: 'change', message: '请选择封面' },
        ],
        bannerUrl: [
          { required: true, trigger: 'change', message: '请选择海报' },
        ],
        status: [{ required: true, trigger: 'change', message: '请选择状态' }],
        tagIds: [
          { required: true, trigger: 'change', message: '请选择文章标签' },
        ],
        content: [
          { required: true, trigger: 'blur', message: '请输入文章内容' },
        ],
        articleCategoryId: [
          { required: true, trigger: 'blur', message: '请选择文章类别' },
        ],
      },
      title: '',
      list: [],
      pickerStartDate: null,
      limit: 1,
      typeOption: [],
      tagOption: [],
      articleId: 'new',
      queryForm: { pageSize: 8, pageNum: 1, appKey: 'WX_PUBLIC_MEHZ' },
      wexinArticleList: [],
    })

    const showEdit = (row) => {
      if (state.articleId == 'new') {
        state.title = '添加文章'
        state.form = {
          fileList: [],
          tagIds: [],
          content: '',
        }
      } else {
        console.log(row)
        // state.ue = window.UE.getEditor('editor')

        // state.ue.ready(() => {
        state.title = '编辑文章'
        row.activeTime = [parseTime(row.startTime), parseTime(row.endTime)]
        row.fileList = [{ name: 'coverUrl', url: row.coverUrl }]
        row.bannerList = [{ name: 'banner', url: row.bannerUrl }]
        row.tagIds = handleData(row.articleTagIds)
        state.form = Object.assign({}, row)
        // })
      }
    }

    const handleData = (arr, checkArr) => {
      for (let i in arr) {
        arr[i] = String(arr[i])
      }
      return arr
    }

    const fetchData = async () => {
      let enterpriseCode = proxy.$route.query.enterpriseCode
          ? proxy.$route.query.enterpriseCode
          : ''
      // let isEnterpriseItem = localStorage.getItem('isEnterpriseItem')
      // if (isEnterpriseItem) {
      //   let item = JSON.parse(isEnterpriseItem)
      //   enterpriseCode = item.enterpriseCode
      // }
      const { data: typeList } = await getCategoryList({
        pageSize: 9999,
        pageNum: 1,
        enterpriseCode: enterpriseCode
      })
      const { data: tagList } = await getLabelLabel({}, 'ARTICLE')
      state.typeOption = typeList.data
      state.tagOption = tagList

      const { data } = await getWexinArticle(state.queryForm)
      state.wexinArticleList = handleDataWexin(data.item)
      console.log(state.wexinArticleList)

      state.articleId = proxy.$route.query.id
      if (state.articleId != 'new') {
        const { data: articleDetail } = await getContentDetail({
          articleId: state.articleId,
        })
        showEdit(articleDetail)
      } else {
        showEdit()
      }
      console.log(proxy.$route.query.id)
    }

    onMounted(() => {
      fetchData()
    })
    // 图片相关接口
    // 上传海报
    const upCover = async (e) => {
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      state.form.coverUrl = data
    }

    const upBannar = async (e) => {
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      state.form.bannerUrl = data
    }

    const handleError = (err, file) => {
      proxy.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed = (files) => {
      proxy.$baseMessage(
        `当前限制选择 ${proxy.limit} 个文件，本次选择了
               ${files.length}
               个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed1 = (files) => {
      proxy.$baseMessage(
        `当前限制选择 ${proxy.limit} 个文件，本次选择了
               ${files.length}
               个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleRemove = () => {
      state.form.coverUrl = ''
    }
    const handleRemove1 = () => {
      state.form.bannerUrl = ''
    }

    const handlePreview = (file) => {
      state.dialogImageUrl = file.url
      state.dialogVisible = true
    }

    // 富文本上传图片
    const handleAddImg = async (e) => {
      console.log(state.form.content, '-=======>>>')
      state.form.content += `<img src="${e}" />`
      console.log(state.form.content, '-=======>>>')
    }
    const submitInfo = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          let msg = ''
          let checkType = state.typeOption.filter(
            (v) => v.id == state.form.articleCategoryId
          )
          state.form.articleCategoryName = checkType[0].name
          if (!!proxy.$route.query.enterpriseCode) {
            state.form.enterpriseCode = proxy.$route.query.enterpriseCode
          }
          if (state.articleId != 'new') {
            let data = await editArticle(state.form)
            msg = data.msg
          } else {
            let data = await saveArticle(state.form)
            msg = data.msg
          }
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          proxy.$pub('reload-router-view')
          // proxy.$router.replace({ path: '/contentCenter/contentManage' })
          // proxy.$router.go(-1)
        }
      })
    }
    const handleDataWexin = (data) => {
      let nData = []
      if (!data || data.length == 0) return false
      data.forEach((item) => {
        let obj = {
          articleId: item.article_id,
          thumbUrl: item.content.news_item[0].thumb_url,
          name: item.content.news_item[0].title,
        }
        nData.push(obj)
      })
      return nData
    }
    // 选择公众号文章
    const chooseWexinArticle = async () => {
      state.articleVisible = true
    }

    const handleSizeChange = async (val) => {
      state.queryForm.pageSize = val
      const { data } = await getWexinArticle(state.queryForm)
      state.wexinArticleList = handleDataWexin(data.item)
    }
    const handleCurrentChange = async (val) => {
      state.queryForm.pageNum = val
      const { data } = await getWexinArticle(state.queryForm)
      state.wexinArticleList = handleDataWexin(data.item)
    }

    const chooseWexinArticleItem = async (articleId) => {
      const { data } = await getWeixinArticleDetail({
        articleId,
        appKey: 'WX_PUBLIC_MEHZ',
      })
      state.form.content = data[0].content
      state.articleVisible = false
    }

    const contentChange = (value) => {
      state.form.content = value
    }

    return {
      ...toRefs(state),
      showEdit,
      contentChange,
      upBannar,
      fetchData,
      handleAddImg,
      submitInfo,
      handleError,
      handleExceed,
      handleExceed1,
      handleRemove,
      handleRemove1,
      handlePreview,
      chooseWexinArticle,
      handleSizeChange,
      handleCurrentChange,
      chooseWexinArticleItem,
      upCover,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}

.demo-drawer__content {
  // height: 85vh;
  overflow: auto;
}
.demo-drawer__footer {
  position: fixed;
  right: 20px;
  bottom: 10px;
}
</style>

<style lang="scss"></style>
